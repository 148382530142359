import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { AdditionalInfo } from '../additionalInfo/additionalInfo.component';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { styleWhenTrue } from '../../utils/style.utils';
import { Markdown } from '../markdown/markdown.component';

import { DataBox, PriceSimulations } from './components';
import { ContainerProps, SectionTitleProps } from './offerSummary.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  margin: 0 auto;
  background-color: ${Color.White};
  --company-color: ${({ $companyColor }: ContainerProps): string => $companyColor};
`;

export const Section: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 60px;
  }

  @media ${Breakpoint.Mobile} {
    & + & {
      margin-top: 40px;
    }
  }
`;

export const SectionTitle: StyledComponent<'div', SectionTitleProps> = styled.div<SectionTitleProps>`
  font-size: 16px;
  line-height: 19px;
  color: ${Color.Black};
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${Color.GreyStroke};

  ${styleWhenTrue(prop('withBiggerFonts'), css`
    font-size: 20px;
  `)}

  @media ${Breakpoint.Mobile} {
    margin-bottom: 20px;
  }
`;

export const StyledAdditionalInfo: StyledComponent<typeof AdditionalInfo> = styled(AdditionalInfo)`
  margin-top: 32px;
`;

export const StyledDataBox: StyledComponent<typeof DataBox> = styled(DataBox)``;

export const DataBoxRow: StyledComponent<'div'> = styled.div`
  display: flex;
  margin-top: 22px;
  margin-bottom: 32px;

  ${StyledDataBox} + ${StyledDataBox} {
    margin-left: 20px;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;

    ${StyledDataBox} + ${StyledDataBox} {
      margin-top: 20px;
      margin-left: 0;
    }
  }
`;

export const StyledPriceSimulations: StyledComponent<typeof PriceSimulations> = styled(PriceSimulations)`
  margin-top: 60px;
`;

export const SectionItemContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  & + & {
    margin-top: 32px;
  }
`;

export const PhotosItemContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  margin: 22px 0 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PhotosHeader: StyledComponent<'div'> = styled.div`
  font-size: 16px;
  line-height: 22px;
`;

export const AddressAnnotation: StyledComponent<typeof Markdown> = styled(Markdown)`
  margin-bottom: 32px;
  margin-top: 22px;
  line-height: 22px;
`;
